import React from 'react';
import Featured from '../assets/433852678_1115605839721508_9060757487114146329_n.jpg';
import FeaturedIcons from '../assets/featured-icon.png';
import { Link } from 'react-router-dom';
import { SiSololearn } from "react-icons/si";
import English from '../assets/united-states.png';
import German from '../assets/germany.png';
import Russian from '../assets/russia.png';
import Italian from '../assets/italy.png';
import Georgian from '../assets/georgia.png';
import Matematica from '../assets/mathematic.png';
import Skills from '../assets/integrity.png';

function ListItem({ image, alt, title, subtitle, isLastItem }) {
  const borderClass = isLastItem ? '' : 'border-b-2';
  return (
    <li className={`ati flex items-center gap-3 ${borderClass}`}>
      <img src={image} alt={alt} width={60}/>
      <p className='Poppins text-[14px]'>{title}<br/><span>{subtitle}</span></p>
    </li>
  );
}

function About({ fontFamily, t }) {
  const languages = [
    { image: English, alt: "english", title: "English", subtitle: "ინგლისური" },
    { image: German, alt: "german", title: "German", subtitle: "გერმანული" },
    { image: Russian, alt: "russian", title: "Russian", subtitle: "რუსული" },
    { image: Italian, alt: "italian", title: "Italian", subtitle: "იტალიური" },
    { image: Georgian, alt: "georgian", title: "Georgian", subtitle: "ქართული" },
    { image: Matematica, alt: "matematika", title: "Mathematics", subtitle: "მათემატიკა" },
    { image: Skills, alt: "skills", title: "Skills", subtitle: "უნარები" }
  ];
  const courses = [
    "აბიტურიენტის კურსები!!",
    "მასწავლებელთა გადამზადება!!",
    "ენის სასაუბრო კურსები!!",
    "დაწყებითი კლასის მოსწავლეების გადამზადება!!",
    "კორპორატიული სწავლება: ორენოვანი მასწავლებლები!!",
    "უცხოელი მასწავლებლის სასაუბრო გაკვეთილები!!",
    "''ნუცას სკოლა'' სკოლამდელებისთვის!!"
  ];
  return (
    <div className="tertmeti flex flex-wrap gap-5 w-full m-auto mt-24 mb-24 justify-center items-center">
        <div className='w-[400px] ml-14'>
            <img src={Featured} alt="featured" className='rva rounded-2xl totxmeti max-w-none w-[400px] h-[520px]'/>
            <Link to='/Contact' className='inline-block text-center w-28 h-28 rounded-full bg-[#f35525] -mt-16 -ml-14'><img src={FeaturedIcons} alt="contactPage" className='w-28 h-28 p-6'/></Link>
        </div>
        <div className='rvas leading-8 w-[500px] lg:-mt-10' style={{ fontFamily: fontFamily }}>
            <h2 className='text-2xl font-bold'>| {t('რატომ უნდა აგვირჩიოთ ჩვენ?')}</h2>
            <h5>{t('ჩვენ ვასწავლით ყველა ასაკის მსმენელს 5 წლიდან')} </h5>
             <hr className='my-4'/>
             <ul>
      {courses.map((course, index) => (
        <li key={index} className='flex items-center gap-3' style={{ fontFamily: fontFamily }}>
          <SiSololearn className='text-orange-400' />
          {t(`${course}`)}
        </li>
      ))}
    </ul>
      <hr className='my-4'/>
      <h5 className='text-lg font-bold'>{t('საერთაშორისო გამოცდებისთვის მომზადება')} <br/> <strong className='w-full space-x-8 text-[#f35525]'> <span className='Poppins border-b-2 border-[#f35525]'>IELTS;</span><span className='Poppins border-b-2 border-[#f35525]'>TOEFL;</span><span className='Poppins border-b-2 border-[#f35525]'>PTE;</span><span className='Poppins border-b-2 border-[#f35525]'>FCE</span><span>etc</span></strong> </h5>
      <hr className='my-4'/>
      <h4 className='font-extrabold text-[#aa6359]' style={{ fontFamily: fontFamily }}>{t('სწავლება მიმდინარეობს როგორც ოფისში ისე')} <span className='Poppins'>ONLINE</span>, {t('ჩვენ ვასწავლით მსოფლიოს ყველა ქვეყანაში!')}</h4>
        </div>
        <div className='tormeti w-[200px] border-2 flex flex-col items-center'>
        <ul className='Arial tormeti cxra p-[10px]'>
      {languages.map((language, index) => (
        <ListItem
          key={index}
          image={language.image}
          alt={language.alt}
          title={language.title}
          subtitle={language.subtitle}
          isLastItem={index === languages.length - 1}
        />
      ))}
    </ul>
   </div>
  </div>
  )
}

export default About
