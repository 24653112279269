import React from 'react'
import Contact from '../contact/Contact'

function ContactPage({ fontFamily, t }) {
  return (
      <Contact fontFamily={fontFamily} t={t} />
  )
}

export default ContactPage
