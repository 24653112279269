import { useEffect } from 'react';

const useCleanHash = () => {
  const handleHashChange = () => {
    if (window.location.hash === '#/') {
      window.history.replaceState(null, null, '/');
    }
  };

  useEffect(() => {
    const hashChangeHandler = () => {
      handleHashChange();
    };

    // Initial check
    handleHashChange();

    // Set up a periodic check (every 500 milliseconds, adjust as needed)
    const intervalId = setInterval(handleHashChange, 1);

    window.addEventListener('hashchange', hashChangeHandler);

    return () => {
      window.removeEventListener('hashchange', hashChangeHandler);
      clearInterval(intervalId);
    };
  }, []); // Run on mount and set up periodic check
};

export default useCleanHash;
