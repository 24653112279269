import React from 'react';
import lectors from '../assets/lectors/433653724_1149245306492625_3384136727662556272_n.png';
import lectors0 from '../assets/lectors/415474990_345333761612178_365072249545994059_n.png';
import lectors1 from '../assets/lectors/433834119_1184039335895608_6248231478078950591.png';
import lectors2 from '../assets/lectors/433879739_1336076317074595_4433795389631208948_n.png'
import lectors3 from '../assets/lectors/433938792_393998133478932_8183514871310137289_n.png';
import lectors4 from '../assets/lectors/331499592_528451106037491_7679036904127150792_n.png';
import lectors5 from '../assets/lectors/Image_2024-04-18_at_14.27.49_3ea54dd.png';
import lectors6 from '../assets/lectors/Image_2024-04-20_at_19.41.26_59316843.png';
import lectors7 from '../assets/lectors/Image_2024-04-20_at_19.42.45_9b27db59.png';
import logus from '../assets/logo.png';

const Cards = ({ fontFamily, t }) => {
    const lecturers = [
        {
          img: lectors1,
          name: 'მაკა გუნაშვილი',
          position: 'დირექტორი/დამფუძნებელი'
        },
        {
          img: lectors0,
          name: 'სოფი ბოჭორიშვილი',
          position: 'გერმანული ენა',
          experience: [
            'Sommerjob, Sozialarbeit und Ausbildung in Deutschland',
            'გოეთეს სასერტიფიკატო გამოცდებისათვის მომზადება'
          ]
        },
        {
          img: lectors,
          name: 'მარიამ სულიკაშვილი',
          position: 'თსუ-ის ქართული ფილოლოგიის მაგისტრი',
          experience: [
            'თსუ-ის ქართული ფილოლოგიის დოქტორანტი',
            'აზერბაიჯანულენოვან მოსწავლეებთან მუშაობის გამოცდილება'
          ]
        },
        {
          img: lectors2,
          name: 'მაიკო ნიგურიანი',
          position: 'პიატიგორსკის ლინგვისტიკის სახელმწიფო უნივერსიტეტის ასპირანტი',
          experience: [
            'რუსული ენის სასერტიფიკატო კურსი',
            'რუსული ენა აბიტურიენტებისთვის, სკოლის მოსწავლეებისთვის, დაწყებითი კლასელებისთვის'
          ]
        },
        {
          img: lectors4,
          name: 'ეკა გუნაშვილი',
          position: 'დაწყებითი კლასების მოსწავლეებისთვის',
          experience: [
            'სასკოლო გაკვეთილების მომზადება ყველა საგანში, მათ შორის ინგლისურის გაძლიერებული და საფუძვლიანი სწავლებით'
          ]
        },
        {
          img: lectors3,
          name: 'ნინი ვაჩეიშვილი',
          position: 'ინგლისური პატარებისთვის, სკოლის მოსწავლეებისთვის, ზრდასრულთათვის',
          experience: [
            'სასაუბრო ინგლისური',
            'თანამედროვე დაჩქარებული და აკადემიური კურსი'
          ]
        },
        {
          img: lectors5,
          name: 'ნინო შუკაკიძე',
          position: "''ნუცას სკოლის'' ხელმძღვანელი",
          experience: [
            'იტალიური და კომპიუტერული პროგრამები, უნარები'
          ]
        },
        {
          img: lectors6,
          name: 'ეთერი ჭიღიტაშვილი',
          position: "სამოქალაქო განათლება",
          experience: [
            'კომპიუტერული პროგრამები, უნარები'
          ]
        },
        {
          img: lectors7,
          name: 'თამუნა თაბაგარი',
          position: ['იტალიური ენა  -  საკომუნიკაციო და აკადემიურ დონეზე']
        },
      ];
  return (
    <div className='w-full px-4 mb-[100px] bg-white'>
      <p className='pl-4 mr-10 ml-10 mb-10 text-[16px] border-l-4 bg-zinc-100 p-2' style={{ fontFamily: fontFamily }}>
      {t('ქალაქ რუსთავში 2022 წელს საგანმანათლებლო სივრცე')} <span className='Poppins'> Linguistic Lab </span> {t('გაიხსნა, რომელიც ორიენტირებულია შეგიქმნათ კომფორტული გარემო და მოგცეთ სათანადო ცოდნა იმ მიმართულებით, რასაც აირჩევთ!')} </p>
      <p className='pl-4 mr-10 ml-10 mb-20 text-[16px] border-l-4 bg-zinc-100 p-2' style={{ fontFamily: fontFamily }}>
      {t('მნიშვნელოვანია აღინიშნოს რომ')} <span className='Poppins'>Linguistic Lab</span> {t('დაკომპლექტებულია პროფესიონალი კადრებით, რომელთაგან თითოეულს აქვს როგორც თეორიული, ისე პრაქტიკული ცოდნა და გამოცდილება, მათ მოსწავლეებთა შორის არა მხოლოდ ქართველი და ადგილობრივი, არამედ უცხოელი მოსწავლეებიც ხშირად არიან, დისტანციური სწავლებით ხარისხიან განათლებას იღებენ მსოფლიოს სხვადასხვა ქვეყნიდან : აშშ, გერმანია, ავსტრია, იტალია, კვიპროსი, ისრაელი და ა.შ.')}
      </p>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
        {lecturers.map((lecturer, index) => (
          <div key={index} className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
            <img src={logus} alt='logo' width={80} className='absolute' />
            <img className='mx-auto bg-white' src={lecturer.img} alt={lecturer.name} style={{objectFit: 'contain', width: '350px', height: '300px'}}/>
            <h2 className='text-2xl font-bold text-center py-8' style={{ fontFamily: fontFamily === 'Arial' ? 'Mtavruli' : 'Uni-sans' }}>{t(`${lecturer.name}`)}</h2>
            <p className='text-[14px] py-2 border-b mx-8' style={{ fontFamily: fontFamily }}>{t(`${lecturer.position}`)}</p>
            {lecturer.experience && lecturer.experience.map((exp, index) => (
              <p key={index} className='text-[14px] py-2 border-b mx-8' style={{ fontFamily: fontFamily }}>{t(`${exp}`)}</p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cards;