import React, { useState, useRef, useEffect } from 'react';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';

const Contact = ({ fontFamily, t }) => {
  const textareaRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    tel: '',
    message: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [formData.message]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.tel || !formData.message) {
      Swal.fire({
        position: "top-center",
        icon: "error",
        title: "Validation Error",
        text: "გთხოვთ შეავსოთ ყველა საჭირო ველი.",
      });
      return; 
    }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: "Validation Error",
            text: "გთხოვთ შეიყვანოთ სწორი ელ - ფოსტის მისამართი.",
          });
          return; 
        }
      const phoneRegex = /^\d{9}$/;
      if (!phoneRegex.test(formData.tel)) {
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: "Validation Error",
          text: "გთხოვთ, შეიყვანოთ სწორი ტელეფონის ნომერი.",
        });
        return; 
      }
    emailjs
      .sendForm('service_uy8xpsu', 'template_c4hriyj', e.target, 'nKnT04W1X6CLg31MH')
      .then((result) => {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: `${result.text}`,
          showConfirmButton: false,
          timer: 1500
        });
        setFormData({
          name: '',
          email: '',
          tel: '',
          message: '',
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: "Oops...",
          text: `${error.text}`,
        });
      });
  };

  return (
    <div className='w-full flex flex-wrap-reverse gap-5 justify-center items-center mt-[100px] mb-[100px]' style={{ fontFamily: fontFamily }}>
    <div className='iframe flex flex-col gap-5 w-[60%] bg-sky-300 p-3 rounded-s-lg'>
      <iframe title='LinguisticLab' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2986.1945350291735!2d45.008458999999995!3d41.543385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDMyJzM2LjIiTiA0NcKwMDAnMzAuNSJF!5e0!3m2!1sen!2sge!4v1713167044118!5m2!1sen!2sge"  loading="lazy" referrerPolicy="no-referrer-when-downgrade"
        width="100%"
        height="540"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
      </div>
        <div className='flex bg-sky-300 rounded-lg min-w-[30%]' style={{ fontFamily: fontFamily }}>
        <form onSubmit={handleSubmit} className='w-full'>
          <div className='flex flex-col p-3'>
            <label htmlFor="name" className='p-2'><sup className='text-[red]'>*</sup> {t('სახელი, გვარი:')}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className='rounded-md outline-none bg-orange-100 p-1'
            />
          </div>
          <div className='flex flex-col p-3'>
            <label htmlFor="email" className='p-2'><sup className='text-[red]'>*</sup> {t('ელ - ფოსტა:')}</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className='rounded-md outline-none bg-orange-100 p-1'
            />
          </div>
          <div className='flex flex-col p-3'>
            <label htmlFor="tel" className='p-2'><sup className='text-[red]'>*</sup> {t('ტელეფონი')}:</label>
            <input
              type="tel"
              id="tel"
              name="tel"
              inputMode='numeric'
              value={formData.tel}
              onChange={handleChange}
              required
              className='rounded-md outline-none bg-orange-100 p-1'
            />
          </div>
          <div className='flex flex-col p-3'>
            <label htmlFor="message" className='p-2'><sup className='text-[red]'>*</sup> {t('კომენტარი')}:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              ref={textareaRef}
              style={{ overflowY: 'hidden' }}
              required
              className='rounded-md outline-none bg-orange-100 p-1'
            />
          </div>
          <div className='flex flex-col p-3'>
            <button type="submit" className='Mtavruli font-extrabold rounded-xl hover:bg-black hover:text-green-600 transition-all duration-1000 p-3 mx-auto mb-3 bg-green-700 w-[200px]'>{t('გაგზავნა')}</button>
            <p> <sup className='text-[red]'>*</sup> {t('ყველა ველის შევსება სავალდებულოა!')} </p>
          </div>
        </form>
        </div>
      </div>
  )
}

export default Contact
