import React, { useState } from 'react';
import { IoImages } from "react-icons/io5";
import { RxVideo } from "react-icons/rx";
import { SiMediamarkt } from "react-icons/si";
import { image1, image2, image3, image4, image5, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, video, video1 } from '../assets/galery/Images'; 

function GalleryPage({ fontFamily, t }) {
      const [activeTab, setActiveTab] = useState('all');
      const [overlayVisible, setOverlayVisible] = useState(false);
      const [currentIndex, setCurrentIndex] = useState(0);
      const [mediaArray, setMediaArray] = useState([]);
    
      const images = [image1, image2, image3, image8, image9, image10, image11, image13, image4, image5, image18, image7, image19, image12, image14, image15, image16, image17,];
      const videos = [video, video1];
    
      const handleTabClick = (tab) => {
        setActiveTab(tab);
      };
    
      const openOverlay = (index, array) => {
        setCurrentIndex(index);
        setMediaArray(array);
        setOverlayVisible(true);
      };
    
      const closeOverlay = () => {
        setOverlayVisible(false);
      };
    
      const moveSlide = (direction) => {
        if (direction === 'prev') {
          setCurrentIndex((prevIndex) => (prevIndex === 0 ? mediaArray.length - 1 : prevIndex - 1));
        } else if (direction === 'next') {
          setCurrentIndex((prevIndex) => (prevIndex === mediaArray.length - 1 ? 0 : prevIndex + 1));
        }
      };
    
      return (
        <div className='mb-[100px] mt-[50px]'>
          <div className='flex justify-between flex-wrap items-end mb-10' style={{ fontFamily: fontFamily }}>
          <h2 className='text-[30px] font-bold ml-5' style={{ whiteSpace: 'pre-line' }}>{t('საუკეთესო გარემო \n საუკეთესო სპეციალისტებით!')}</h2>
          <div className='mr-5 mt-10'>
              <button onClick={() => handleTabClick('all')} className={`mx-1 rounded-md bg-[#f35525] max-sm:px-2 px-4 py-1 ${activeTab === 'all' && 'bg-gray-500'}`}><span className='flex max-sm:text-[14px] items-center gap-3'><SiMediamarkt />{t('ყველა')}</span></button>
              <button onClick={() => handleTabClick('images')} className={`mx-1 rounded-md bg-[#f35525] max-sm:px-2 px-4 py-1 ${activeTab === 'images' && 'bg-gray-500'}`}> <span className='flex max-sm:text-[14px] items-center gap-3'><IoImages /> {t('სურათები')} </span> </button>
              <button onClick={() => handleTabClick('videos')} className={`mx-1 rounded-md bg-[#f35525] max-sm:px-2 px-4 py-1 ${activeTab === 'videos' && 'bg-gray-500'}`}><span className='flex max-sm:text-[14px] items-center gap-3'><RxVideo />{t('ვიდეოები')}</span> </button>
            </div>
          </div>
          <div className='w-full'>
            {activeTab === 'all' && (
              <div className='gridcontainer grid grid-cols-3 gap-3 ml-3 mr-3'>
                {images.map((image, index) => (
                  <img className='images' key={index} src={image} alt={index} width={500} style={{ minHeight: '350px', maxHeight: '580px' }} onClick={() => openOverlay(index, images)} />
                ))}
                {videos.map((video, index) => (
                  <video className='videos' controls width={500} style={{ backgroundColor: 'black', height: '300px' }} onClick={() => openOverlay(index, videos)}>
                    <source key={index} src={video} type="video/mp4" style={{ objectFit: 'cover', width: '100%', height: '300px' }} />
                  </video>
                ))}
              </div>
            )}
            {activeTab === 'images' && (
              <div className='gridcontainer grid grid-cols-3 gap-3 ml-3 mr-3'>
                {images.map((image, index) => (
                  <img className='images' key={index} src={image} alt={index} width={500} style={{ minHeight: '350px', maxHeight: '580px' }} onClick={() => openOverlay(index, images)} />
                ))}
              </div>
            )}
            {activeTab === 'videos' && (
              <div className='gridcontainer grid grid-cols-3 gap-3 ml-3 mr-3'>
                {videos.map((video, index) => (
                  <video className='videos' controls width={500} style={{ backgroundColor: 'black', height: '300px' }} onClick={() => openOverlay(index, videos)}>
                    <source key={index} src={video} type="video/mp4" />
                  </video>
                ))}
              </div>
            )}
          </div>
              {overlayVisible && (
            <div className="overlay">
              <span className="close-btn" onClick={closeOverlay}>×</span>
              <div className="overlay-content">
                {mediaArray.map((media, index) => (
                  <div className='imigecontain' key={index} style={{ display: index === currentIndex ? 'block' : 'none' }}>
                    {typeof media === 'string' && (
                      <img className="overlay-media" src={media} alt={`Media ${index}`} />
                    )}
                  </div>
                ))}
                <button className="prev-btn" onClick={() => moveSlide('prev')}>&#10094;</button>
                <button className="next-btn" onClick={() => moveSlide('next')}>&#10095;</button>
              </div>
            </div>
          )}
        </div>
      );
    };

export default GalleryPage
