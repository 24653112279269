import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';


const CustomNavLink = ({ to, children }) => {
    const location = useLocation();
    const isActive = location.pathname === to;
  
    return (
      <NavLink to={to} activeclassname="text-[#71bcff]" className={`sami p-4 ${isActive ? 'text-[#71bcff]' : 'hover:text-[#71bcff]'}`}>
        {children}
      </NavLink>
    );
  };

export default CustomNavLink
