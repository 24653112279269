import { useEffect } from 'react';

const Statistics = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//counter.top.ge/counter.js";
    script.async = true;
    
    const div = document.createElement('div');
    div.id = "top-ge-counter-container";
    div.setAttribute('data-site-id', '117358');
    
    const topgeDiv = document.getElementById('TopGe');
    if (topgeDiv) {
      topgeDiv.appendChild(script);
      topgeDiv.appendChild(div);
    }
    
    return () => {
      if (topgeDiv) {
        topgeDiv.removeChild(script);
        topgeDiv.removeChild(div);
      }
    };
  }, []); 
  
  return null; 
};

export default Statistics;
