import React from "react";
import Logo from '../assets/logo.png';
import { NavLink, useLocation } from 'react-router-dom';
import { FaFacebookSquare, FaPhoneSquareAlt } from "react-icons/fa";
import { ImMail } from "react-icons/im";
import { FaAddressCard } from "react-icons/fa6";
import Statistics from "../statistic/Statistic";

const CustomNavLink = ({ to, children }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const handleExternalLinkClick = () => {
    window.scrollTo(0, 0); 
  };
  return (
    <NavLink to={to} onClick={handleExternalLinkClick} activeclassname="text-[#71bcff]" className={`sami p-4 ${isActive ? 'text-[#71bcff]' : 'hover:text-[#71bcff]'}`}>
      {children}
    </NavLink>
  );
};

const Footer = ({ fontFamily, t }) => {
  return (
    <>
    <div className="relative flex flex-wrap justify-between w-full pt-10 pb-5 px-4 gap-8 bg-black text-gray-300">
      <div className="flex flex-col flex-wrap">
        <div>
        <div className="flex items-center gap-3">
          <img src={Logo} alt="logo" width={60} className="ertiaso"/>
          <h1 className="Poppins ertias text-3xl font-bold text-[#71bcff]">
            Linguistic Lab
          </h1>
          </div>
        <p className="py-4" style={{ fontFamily: fontFamily }}>
        {t('კონსულტანტი განათლების საკითხებში')}
        </p>
        </div>
        <div className="flex gap-5 justify-start md:w-[75%] my-6">
          <NavLink to="https://www.facebook.com/profile.php?id=100084066712177&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"> <FaFacebookSquare size={30} className="cursor-pointer" /> </NavLink>
          <NavLink to="tel:+995595300305" className="flex items-center text-black bg-[#d1d5db] pr-4 rounded"><FaPhoneSquareAlt size={30} className="mr-4" />595 300 305</NavLink>
        </div>
      </div>
      <div className="flex lg:flex-col max-md:flex-wrap-reverse md:flex-wrap-reverse">
      <div className="m-auto">
        <ul className="flex max-sm:text-[12px]" style={{ fontFamily: fontFamily === 'Arial' ? 'Mtavruli' : 'Uni-sans' }}>
          <li className="p-4 max-sm:p-0 border-b border-gray-600"><CustomNavLink to="/">{t('მთავარი')}</CustomNavLink></li>
          <li className="p-4 max-sm:p-0 border-b border-gray-600"><CustomNavLink to="/About">{t('ჩვენს შესახებ')}</CustomNavLink></li>
          <li className="p-4 max-sm:p-0 border-b border-gray-600"><CustomNavLink to="/Gallery">{t('გალერეა')}</CustomNavLink></li>
          <li className="p-4 max-sm:p-0 border-b border-gray-600"><CustomNavLink to="/Contact">{t('კონტაქტი')}</CustomNavLink></li>
        </ul>
        </div>
        <div id="TopGe" className="adres md:-mt-[55px] lg:-mt-0 sm:-mt-[55px]">
          <NavLink to="mailto:Linguisticlab22@gmail.com" className="Poppins flex w-fit my-5 items-center text-black bg-[#d1d5db] pr-4 rounded">
            <ImMail size={30} className="p-1 mr-3" /> Linguisticlab22@gmail.com
          </NavLink>
          <NavLink to="https://maps.app.goo.gl/FFPY92gDVnzqVKEh7" className="flex w-fit my-5 items-center text-black bg-[#d1d5db] pr-4 rounded" style={{ fontFamily: fontFamily }}>
            <FaAddressCard size={30} className="p-1 mr-3" />{t('რუსთავი, მშენებელთა 4 (კოსტავას გამზირზე, შადრევანთან)')}
          </NavLink>
          <Statistics />
        </div>
        </div>
        </div>
        <div className="text-center bg-[#14191d] text-white">
      <p className="Poppins max-sm:text-[14px] py-1"> © All rights reserved. Produced by<NavLink to="https://www.facebook.com/profile.php?id=61557220946939" target="_blank" rel="noopener noreferrer"> Blaster Studio</NavLink></p>
    </div>
    </>
  );
};

export default Footer;
