import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { IoCallSharp } from "react-icons/io5";
import { HiMail } from "react-icons/hi";
import { FaMap } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import Logo from '../assets/logo.png';
import CustomNavLink from "./CustomNavLink";
const maps = "https://maps.app.goo.gl/FFPY92gDVnzqVKEh7";


const Navbar = ({ changeLanguage, fontFamily, t, language }) => {
  const [nav, setNav] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1000);
    };

    handleResize(); 
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="bg-[#14191d]">
     <div className="flex justify-between mx-4 h-10 items-center border-b border-[#e7eaec]">
        <div className="flex gap-5 text-[14px]">
            <NavLink to="mailto:Linguisticlab22@gmail.com" activeclassname="text-[#71bcff]">
              <p className="flex items-center gap-1 cursor-pointer text-[#7a7a7a]">
                <HiMail size={25} className="text-[#f35525]" /> <span className="Poppins max-sm:text-[12px]">Linguisticlab22@gmail.com</span>
              </p>
            </NavLink>
            {!isSmallScreen && (
            <NavLink to={maps} target="_blank"
            activeclassname="text-[#71bcff]">
              <p className="flex items-center gap-1 cursor-pointer text-[#7a7a7a]">
               <FaMap size={20} className="text-[#f35525]" /> <span style={{ fontFamily: fontFamily }}>{t('რუსთავი, მშენებელთა 4 (კოსტავას გამზირზე, შადრევანთან)')}</span>
              </p>
            </NavLink>)}
        </div>
        <div className="flex gap-3">
          <NavLink to="tel:+995595300305" className="flex items-center gap-3 cursor-pointer text-[#f35525] opacity-70 hover:opacity-100 transition-opacity duration-300">
          <span className="w-fit text-[#888888] font-bold max-sm:text-[12px]">595 30 03 05 </span><IoCallSharp size={30} className="transform: -rotate-90" />
          </NavLink>
          <NavLink to="https://www.facebook.com/profile.php?id=100084066712177&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <FaFacebook size={30} className="cursor-pointer text-[#f35525] opacity-70 hover:opacity-100 transition-opacity duration-300" />
          </NavLink>
        </div>
      </div>
      <nav className="flex justify-between items-center h-24 w-full px-4 text-[#7a7a7a]">
      <CustomNavLink to="/">
        <div className="flex items-center gap-3">
        <img src={Logo} alt="logo" width={60} className="ertiaso"/>
        <h1 className="Poppins ertias text-3xl font-bold text-[#71bcff]">
          {nav ? "" : "Linguistic Lab"}
        </h1>
        </div>
        </CustomNavLink>
        <ul className="ori hidden lg:flex gap-3 text-[16px] font-medium cursor-pointer" style={{ fontFamily: fontFamily === 'Arial' ? 'Mtavruli' : 'Uni-sans' }}>
          <li><CustomNavLink to="/">{t('მთავარი')}</CustomNavLink></li>
          <li><CustomNavLink to="/About">{t('ჩვენს შესახებ')}</CustomNavLink></li>
          <li><CustomNavLink to="/Gallery">{t('გალერეა')}</CustomNavLink></li>
          <li><CustomNavLink to="/Contact">{t('კონტაქტი')}</CustomNavLink></li>
        </ul>
        <div className="slide rounded-xl bg-[#ffd4d4] w-[90px] h-[30px] flex items-center justify-around cursor-pointer">
  <div className={`text-[14px] hover:text-blue-700 font-bold cursor-pointer Poppins ${language === 'ge' ? 'text-red-600' : ''}`}  onClick={() => changeLanguage('ge')}> Geo </div>  
  <div className={`text-[14px] hover:text-blue-700 font-bold cursor-pointer Poppins ${language === 'en' ? 'text-red-600' : ''}`} onClick={() => changeLanguage('en')}> Eng </div>
</div>
        <div onClick={handleNav} className="cursor-pointer block lg:hidden">
          {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
        </div>
        <ul onClick={handleNav} style={{ fontFamily: fontFamily === 'Arial' ? 'Mtavruli' : 'Uni-sans' }}
          className={
            nav
              ? "Mtavruli fixed z-30 left-0 top-0 w-[52%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500"
              : "ease-in-out duration-500 fixed left-[-100%]"
          }
        >
          <div className="flex p-4 items-center gap-3">
        <img src={Logo} alt="logo" width={60} className="ertiaso"/>
        <h1 className="Poppins text-xl font-bold text-[#71bcff]">  Linguistic Lab</h1>
          </div>
          <li className="p-4 border-b border-gray-600" ><CustomNavLink to="/">{t('მთავარი')}</CustomNavLink></li>
          <li className="p-4 border-b border-gray-600"><CustomNavLink to="/About">{t('ჩვენს შესახებ')}</CustomNavLink></li>
          <li className="p-4 border-b border-gray-600"><CustomNavLink to="/Gallery">{t('გალერეა')}</CustomNavLink></li>
          <li className="p-4"><CustomNavLink to="/Contact">{t('კონტაქტი')}</CustomNavLink></li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
