import React from 'react';
import { ReactTyped } from "react-typed";
import LinguisticLab from '../assets/433834119_1184039335895608_6248231478078950591.png';

const Hero = ({scrollToAbout, fontFamily, t }) => {
  return (
    <div className='bg-[#3f7cb1] w-full h-[510px]'>
      <div className="erti w-[90%] grid h-full justify-end mr-10">
        <img src={LinguisticLab} alt="LinguisticLab" className='-mt-[40px] h-[550px]'/>
      </div>
      <h1 className='otxi xuti absolute top-10 left-20 text-2xl font-bold pt-44 text-white' style={{ fontFamily: fontFamily }}>
        {t('მოსამზადებელი კურსები')} <br/> <span className='Poppins'>Linguistic Lab</span> {t('- ში')}
      </h1>
      <div className='otxi eqvsi absolute flex items-center bottom-48 left-20'>
        <p className='md:text-3xl sm:text-2xl text-xl font-bold py-4 text-white' style={{ fontFamily: fontFamily }}>
          {t('ეროვნული გამოცდები')}
        </p>
        <ReactTyped
          strings={['TOEFL', 'IELTS', 'FCE']} 
          typeSpeed={120}
          backSpeed={140}
          loop
          className='Poppins md:text-3xl sm:text-2xl text-xl font-bold md:pl-4 pl-2 text-white'
        />
        <p className='md:text-3xl ml-1 sm:text-2xl text-xl font-bold py-4 text-white' style={{ fontFamily: fontFamily }}>  {t('- ში')}</p>         
      </div>
      <div className='otxi shvidi absolute bottom-10 left-20'>
        <p className='md:text-2xl text-xl font-bold text-[#66ff3f]' style={{ fontFamily: fontFamily }}>{t('განათლება - მომავლის გასაღები')}</p>
        <button onClick={scrollToAbout} className='bg-[#00df9a] hover:text-[#00df9a] hover:bg-black transition-all duration-1000 font-extrabold w-[200px] rounded-md my-6 mx-auto py-3 text-black' style={{ fontFamily: fontFamily === 'Arial' ? '' : 'Uni-sans' }}>{t('რატომ ჩვენ?')}</button>
      </div>
    </div>
  );
};

export default Hero;
