import React  from 'react';
import About from '../about/About';
import Cards from '../cards/Cards';

function AboutPage({ fontFamily, t }) {

  return (
    <>
      <About fontFamily={fontFamily} t={t} />
      <Cards fontFamily={fontFamily} t={t} />
    </>
  )
}

export default AboutPage
