import React, { useRef, useState } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/footer/Footer';
import About from './components/about/About';
import Newsletter from './components/newsletter/Newsletter';
import Hero from './components/hero/UpHero';
import Contact from './components/contact/Contact';
import Navbar from './components/navbar/Navbar';
import AboutPage from './components/pages/AboutPage';
import GalleryPage from './components/pages/GalleryPage';
import ContactPage from './components/pages/ContactPage';
import { createHashHistory } from 'history';
import useCleanHash from './useCleanHash';
import { useTranslation } from 'react-i18next';
import SeouseHelmet from './SeouseHelmet';

const customHashHistory = createHashHistory({ hashType: 'slash' });

function App() {
  const [fontFamily, setFontFamily] = useState('Arial');
  const [language, setLanguage] = useState('ge');
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLanguage(language);
    let font;
    switch (language) {
      case 'en':
        font = 'Poppins';
        break;
      case 'ge':
        font = 'Arial';
        break;
      default:
        font = 'Arial'; 
    }
    setFontFamily(font);
  };

  return (
    <>
      <MyApp fontFamily={fontFamily} changeLanguage={changeLanguage} language={language} />
    </>
  );
}

function MyApp({ fontFamily, changeLanguage, language }) {
  const { t } = useTranslation();
  useCleanHash();
  const aboutRef = useRef(null);

  const scrollToAbout = () => {
    if (aboutRef.current) {
      const aboutPosition = aboutRef.current.getBoundingClientRect().top;
      window.scrollTo({
        top: aboutPosition - 60,
        behavior: 'smooth'
      });
    }
  };

  return (
    <HashRouter history={customHashHistory}>
      <SeouseHelmet />
      <Navbar changeLanguage={changeLanguage} fontFamily={fontFamily} t={t} language={language} />
      <Routes>
        <Route exact path="/" element={
            <>
              <Hero scrollToAbout={scrollToAbout} fontFamily={fontFamily} t={t} />
              <div id="about-section" ref={aboutRef}>
                <About fontFamily={fontFamily} t={t} />
              </div>
              <Newsletter fontFamily={fontFamily} t={t}/>
              <Contact fontFamily={fontFamily} t={t} />
            </>
          }
        />
        <Route path="/About" element={<AboutPage fontFamily={fontFamily} t={t} />} />
        <Route path="/Gallery" element={<GalleryPage fontFamily={fontFamily} t={t} />} />
        <Route path="/Contact" element={<ContactPage fontFamily={fontFamily} t={t}  />} />
      </Routes>
      <Footer fontFamily={fontFamily} t={t} />
    </HashRouter>
  );
}

export default App;
