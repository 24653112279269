import React, { useState, useRef } from 'react';
import Auditoria from '../assets/434151528_947772933544221_6807840502875628010_n.jpg';
import Students from '../assets/konvownv-wvekjnwejnv-kjncwjenv.jpg';
import { FaPlay } from "react-icons/fa";
import Video from '../assets/lv_0_20240131191606.mp4';

const data = [
  {
    text: "უამრავი მაღალქულიანი აბიტურიენტი ეროვნულ გამოცდებზე",
    background: "#e7d3cd"
  },
  {
    text: "IELTS; TOEFL; PTE; FCE - ის უამრავი წარმატებული მსმენელი",
    background: "#e7d3cd"
  },
  {
    text: "კორპორატიული სწავლების უამრავი სერტიფიცირებული",
    background: "#e7d3cd"
  },
  {
    text: "უამრავი წარმატებული სტუდენტი და ემიგრანტი მსოფლიოს ბევრ ქვეყანაში",
    background: "#e7d3cd"
  }
];

const Newsletter = ({ width, height, fontFamily, t }) => {
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef(null);

  const yearsSince2022 = new Date().getFullYear() - 2022;

  const aspectRatio = width / height;
  const maxWidth = Math.min(width, 850);
  const maxHeight = maxWidth / aspectRatio;

  const handlePlayButtonClick = () => {
    setShowVideo(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleVideoEnded = () => {
    setShowVideo(false);
  };

  return (
    <>
      <div className='relative'>
        <img src={Auditoria} alt="auditoria" className='backbath w-full h-[700px] blur-sm' />
        <div className='titlepath absolute top-[30%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-full' style={{ fontFamily: fontFamily }}>
          <h6 className='text-4xl font-bold text-[#193e5c]'>| Linguistic Lab</h6>
          <h2 className='text-2xl font-semibold text-[#162e42]'> {t('ენების დაუფლება, წარმატების დაუფლება: შენი გზა იწყება აქ!')}</h2>
        </div>
        <div className="titpath videopath absolute top-[85%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[850px] h-[400px] rounded-xl">
          {!showVideo && (
            <img
              className="titpath inlipath absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[850px] h-[400px] rounded-xl"
              src={Students}
              alt='#'
            />
          )}

          {!showVideo && (
            <button
              className="absolute opacity-90 bg-[#ee6d42] rounded-full w-[80px] h-[80px] z-20 top-[60%] left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              onClick={handlePlayButtonClick}
            >
              <div className="w-[50px] h-[50px] bg-white rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <FaPlay className="text-[#f35525] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  play
                </FaPlay>
              </div>
            </button>
          )}
          {showVideo && (
            <video
              ref={videoRef}
              className="titpath inlipath absolute rounded-xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              controls
              autoPlay
              onEnded={handleVideoEnded}
              width={maxWidth}
              height={maxHeight}
              style={{ objectFit: 'cover', width: '100%', height: '100%' }} 
            >
              <source src={Video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </div>
      <div className='tripath flex justify-around items-center flex-wrap gap-8 mt-[200px] mb-[100px] mr-5 ml-5'>
      {data.map((item, index) => (
        <div key={index} className='relative rounded-xl p-5 flex justify-evenly items-center w-[240px] h-[80px]' style={{ backgroundColor: item.background }}>
          <p className='Arial text-[14px] flex flex-wrap'>{t(`${item.text}`)}</p>
          <span className='Poppins absolute text-center pt-1 -right-4 -top-5 w-28 h-8 rounded-full bg-[#f35525]'>for <mark className='bg-transparent'>{yearsSince2022}</mark> years</span>
        </div>
      ))}
      </div>
    </>
  );
      }
export default Newsletter;
