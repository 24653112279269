import React from 'react';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';

function SeouseHelmet() {
        const metaTags = {
          title: 'Linguistic Lab - მიიღე ხარისხიანი განათლება',
          description: 'ენების დაუფლება, წარმატების დაუფლება: შენი გზა იწყება აქ! Linguistic Lab - მიიღე ხარისხიანი განათლება',
          keywords: 'ინგლისური, გერმანული, რუსული, იტალიური, ქართული, მათემატიკა, უნარები, მოსამზადებელი კურსები, ეროვნული გამოცდები, განათლება, აბიტურიენტის კურსები, მასწავლებელთა გადამზადება, ენის სასაუბრო კურსები, დაწყებითი კლასის მოსწავლეების გადამზადება, კორპორატიული სწავლება, ორენოვანი მასწავლებლები, უცხოელი მასწავლებლის სასაუბრო გაკვეთილები, ნუცას სკოლა, IELTS, TOEFL, PTE, FCE, ONLINE, სწავლება, ენების დაუფლება, აბიტურიენტი, Linguistic Lab, Linguisticlab, გოეთეს სასერტიფიკატო გამოცდები, იტალიური, კომპიუტერული პროგრამები, აკადემიური კურსი, საუკეთესო გარემო',
          canonical: 'https://linguisticlab.ge/',
        };
      
        return (
          <HelmetProvider>
            {Object.entries(metaTags).map(([key, value]) => {
              return (
                <Helmet key={key}>
                  {key === 'canonical' ? (
                    <link rel="canonical" href={value} />
                  ) : (
                    <meta name={key} content={value} />
                  )}
                </Helmet>
              );
            })}
          </HelmetProvider>
        );
}

export default SeouseHelmet
